<template lang="pug">
  div
    base-snackbar(
      :errors="customerGetErrors"
    )
    v-row
      v-col(lg="3" cols="12")
        v-row
          v-col(cols="12")
            v-autocomplete(
              label="Company"
              :items="customerList"
              item-value="id"
              item-text="company"
              v-model="customerId"
              :loading="customerGetting"
            )
              template(v-slot:item="{ item }")
                span.mr-2.primary--text {{ item.id }}
                small.blue-grey--text.text--darken-1 {{ item.company }}
          v-col(cols="12")
            v-text-field(
              label="Contact Name"
              v-model="contactName"
            )
          v-col(cols="12")
            v-text-field(
              label="Phone #"
              v-model="contactNumber"
            )
          v-col(cols="12")
            v-text-field(
              label="Email Address"
              v-model="email"
            )
          v-col(cols="12")
            v-btn(
              color="green"
              dark
              dense
              block
              @click="store"
              :loading="billingPosting"
            )
              span Save
      v-col(lg="9" cols="12")
        v-data-table(
          :loading="orderGetting"
          :headers="headers"
          :items="details"
          dense
          hide-default-footer
        )
          template(v-slot:item="{ item, index, expand, isExpanded }")
            tr
              td {{ index + 1 }}
              td.text-center {{ drFormat(item.invoiceId) }}
              td {{ item.date.dateFormat() }}
              td.text-right {{ item.amount ? item.amount.phpFormat() : null }}
              td {{ item.status }}
              td
                v-btn(
                  text
                  color="red"
                  small
                  @click="removeItem(index)"
                )
                  v-icon mdi-delete
                v-btn(
                  text
                  color="blue-grey"
                  small
                  @click="expand(!isExpanded)"
                )
                  v-icon(:class="isExpanded ? 'is-expanded' : ''") mdi-chevron-down
          template(v-slot:expanded-item="{ item }")
            tr
              td(colspan="5")
                v-simple-table(dense)
                  template(v-slot:default)
                    thead
                      tr
                        th Amount Paid
                        th Actual Amount
                        th Particulars
                    tbody
                      tr
                        td {{ item.paidAmount ? item.paidAmount.phpFormat() : null }}
                        td {{ item.actualAmount ? item.actualAmount.phpFormat() : null }}
                        td {{ item.particulars }}
          template(v-slot:body.append)
            tr
              th(colspan="3") Total
              td.text-right {{ totalAmount.phpFormat() }}
              td &nbsp;
            tr
              td(colspan="2")
                v-autocomplete(
                  label="Invoice No"
                  v-model="itemSelected"
                  :items="billingStatementUnselected"
                  item-value="id"
                  item-text="invoiceId"
                  return-object
                )
                  template(v-slot:selection="{ item }")
                    span {{ drFormat(item.invoiceId) }}
                  template(v-slot:item="{ item }")
                    span.mr-2.blue-grey--text.text--darken-2 {{ drFormat(item.invoiceId) }}
                    small.blue-grey--text.text--darken-2 {{ item.amount.phpFormat() }}
              td
                v-text-field(
                  label="Date"
                  :value="date"
                  readonly
                  type="date"
                )
              td
                v-text-field(
                  label="Particulars"
                  v-model="particulars"
                )
              td
                v-text-field(
                  label="Amount"
                  readonly
                  :value="amount"
                )
              td
                v-btn(
                  text
                  color="green"
                  small
                  @click="addToBillingStatement"
                )
                  v-icon mdi-plus

</template>
<style lang="sass" scoped>
  .is-expanded
    transform: rotate(-180deg)
</style>
<script>
import billingRepository from '@/repositories/billing.repository'
import customerRepository from '@/repositories/customer.repository'
import orderRepository from '@/repositories/order.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [billingGetVars, billingGetVarNames] = requestVars({ identifier: 'billings', hasData: false, request: 'post' })
const [customerGetVars, customerGetVarNames] = requestVars({ identifier: 'customer' })
const [orderGetVars, orderGetVarNames] = requestVars({ identifier: 'order' })

const billingGetHandler = new VueRequestHandler(null, billingGetVarNames)
const orderGetHandler = new VueRequestHandler(null, orderGetVarNames)
const customerGetHandler = new VueRequestHandler(null, customerGetVarNames)

const inputVars = () => ({
  customerId: null,
  contactName: null,
  contactNumber: null,
  email: null,
  details: [],
})

function generateRandomNumber () {
  return Math.round(Math.random() * 1000) + 1
}

const modifyBillingStatementAttributes = item => ({
  id: item.id,
  invoiceId: item.invoice.id,
  status: item.status ? item.status.name : null,
  date: item.order_date,
  particulars: null,
  amount: item.detail ? item.detail.amount || null : null,
  paidAmount: item.detail ? item.detail.paid_amount || null : null,
  actualAmount: item.detail ? item.detail.actual_amount || null : null,
})

function convertToBillingStatement (data) {
  return data.map(modifyBillingStatementAttributes)
}

const defaultItemInputs = () => ({
  itemSelected: {},
  particulars: null,
})

const tableVars = () => ({
  headers: [
    { text: 'Item', value: 'stockId', width: 80 },
    { text: 'Invoice No.', value: 'invoiceId', width: 120, align: 'center' },
    { text: 'Date', value: 'date', width: 100 },
    { text: 'Amount', value: 'amount', width: 100 },
    { text: 'Status', value: 'orderStatusId', width: 100 },
    { text: 'Config', sortable: false, width: 200 },
  ],
  editIndex: null,
})
export default {
  name: 'CreateBilling',
  data () {
    return {
      ...inputVars(),
      ...tableVars(),
      ...defaultItemInputs(),
      ...customerGetVars,
      ...orderGetVars,
      ...billingGetVars,
    }
  },
  computed: {
    totalAmount () {
      return this.details.reduce((total, item) => total + item.amount, 0)
    },
    billingStatementUnselected () {
      const billingStatementId = this.details.map(item => item.id)
      return this.orderList
        .filter(item => !billingStatementId.includes(item.id))
        .map(modifyBillingStatementAttributes)
    },
    invoiceId () {
      return this.itemSelected.invoiceId || 0
    },
    amount () {
      return this.itemSelected.amount
    },
    date () {
      return this.itemSelected.date || ''
    },
    customerSelected () {
      return Window.objectChangeKeyCase(this.customerList.find(item => this.customerId === item.id) || {})
    },
  },
  created () {
    this.getCustomers()
  },
  watch: {
    customerId () {
      this.getOrderBillings()
    },
    orderList (data) {
      this.details = convertToBillingStatement(data)
    },
    customerSelected (data) {
      this.contactName = data.company
      this.contactNumber = data.contactNumber
    },
  },
  methods: {
    store () {
      const handler = billingGetHandler
      const repository = billingRepository.store
      const data = this.getFieldInputs()
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    getFieldInputs () {
      const defaults = inputVars()
      return Object.keys(defaults)
        .reduce((result, key) => {
          const keySnake = key.camelToSnakeCase()
          if (key === 'details') {
            result[keySnake] = this[key].map(item => {
              delete item.id
              return Window.objectChangeKeyCase(item, 'camelToSnakeCase')
            })
            return result
          }
          result[keySnake] = this[key]
          return result
        }, {})
    },
    getCustomers () {
      const handler = customerGetHandler
      const repository = customerRepository.index
      const params = this.getCustomerIndexParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getOrderBillings () {
      const handler = orderGetHandler
      const repository = orderRepository.billings
      const params = this.getOrderBillingParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getOrderBillingParams () {
      return {
        customer: this.customerId,
      }
    },
    getCustomerIndexParams () {
      return {

      }
    },
    addToBillingStatement () {
      if (!this.invoiceId) return
      const { id } = this.itemSelected
      const data = { id, ...this.itemSelected }
      const result = this.details.objectInsertion(data, 'invoiceId', 'invoiceId')
      this.details = result.map(item => Object.assign({ ...item }))
      this.itemInputsReset()
    },
    itemInputsReset () {
      const defaults = defaultItemInputs()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
    editItem (index) {
      this.editIndex = index
    },
    removeItem (index) {
      this.details.splice(index, 1)
    },
    drFormat (invoice) {
      return `DR${invoice.toString().padStart(6, '0')}`
    },
  },
}
</script>